<template>
  <v-app>
    <v-main class="main">
      <v-container fluid style="height: 100%">
        <login />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Login from "./Login.vue";

export default {
  components: { Login },
};
</script>
<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,500;0,800;1,500&display=swap')

$body-font-family: 'Noto Sans'
$title-font: 'Noto Sans'

.v-application
  background-color: var(--v-background-base) !important

  // Текста
  font-family: $body-font-family, sans-serif !important
  label, [class*='text-'], [class*='__label'], a
    color: rgb(33, 33, 33)!important

  .title, header [class*='__title']  // To pin point specific classes of some components
    color: rgb(33, 33, 33)
    font-weight: 600
    line-height: 158%
    font-size: 18px
    letter-spacing: -0.01em
  table thead th span
    color: var(--v-blue-grey-base)
    font-weight: 500
    font-size: 14px
    line-height: 158%
    letter-spacing: -0.01em
  .caption
    font-family: $body-font-family, sans-serif !important
    color: var(--v-blue-grey-base) !important
  .subtitle
    font-style: normal
    font-weight: 600
    font-size: 0.875rem
    color: var(--v-blue-grey-base)

// Кнопки
.v-btn:not(.v-btn--fab):not(.v-btn--icon):not(.v-btn--rounded):not(.btn-normale)
  border-radius:10px!important
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg
  color: var(--v-blue-grey-base) !important
  background-color: var(--v-blue-grey-lighten1) !important
.v-btn
  text-transform: none !important // Отключен капс

.block__content
  border: 1px solid var(--v-blue-grey-lighten1) !important
  border-radius: 10px!important
  background: #FBFBFB!important
.block__content>.v-data-table__wrapper
  border-radius: 10px!important

// Нижняя линия у полей для ввода и outlined inputs
div.v-input:not(.v-input--is-focused):not(.error--text) fieldset, div.v-input:not(.v-input--is-focused):not(.error--text) .v-input__slot::before
  border-color: var(--v-blue-grey-lighten1) !important

// Отступы
.v-input__slot
  margin-bottom: 1px !important
.v-text-field
  margin-top: 2px !important

  // Global ScrollBar
/* width */
::-webkit-scrollbar
  width: 5px
/* Track */
::-webkit-scrollbar-track
  background: #E8ECFF
/* Handle */
::-webkit-scrollbar-thumb
  background: #C6C8D5
  border-radius: 2.5px
/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #2757FF
</style>
